'use client'
import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Link from 'next/link'
import {Img} from "@/components/Img";
import {white} from "@/styles/globalStyleVars";
import {ImgLeftParallax} from "@/components/LeftRightParallax";
import useContainerOffset from "@/components/useContainerOffset";
import reactHtmlParser from "react-html-parser";
import 'haspr-cursor/dist/cursor.css' // Import Style sheet
import {
    CURSOR_COLOR,
    CURSOR_EXCLUSION,
    CURSOR_HIDE,
    CURSOR_SHOW,
    CURSOR_STICKY, CURSOR_TEXT,
    CURSOR_VIDEO,
    DispatchCursor
} from "haspr-cursor";


const MyComponent = ({data}) => {
    const [offset, setOffset] = useState(90)

    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const updateIsDesktop = () => setIsDesktop(window.innerWidth > 991);

        updateIsDesktop(); // Initial check
        window.addEventListener('resize', updateIsDesktop);

        return () => window.removeEventListener('resize', updateIsDesktop);
    }, []);

    useEffect(() => {
        const updateOffset = () => setOffset(document.querySelector('.container')?.offsetLeft);

        window.addEventListener('load', updateOffset);
        window.addEventListener('resize', updateOffset);

        updateOffset(); // Initial calculation

        return () => {
            window.removeEventListener('load', updateOffset);
            window.removeEventListener('resize', updateOffset);
        }
    }, []);
    const dispatch = DispatchCursor();

    return (
        <div onMouseEnter={CURSOR_SHOW} onMouseLeave={CURSOR_HIDE}>
            <StyledComponent offset={offset} onMouseEnter={() => CURSOR_COLOR('BLACK')}
                             onMouseLeave={() => CURSOR_COLOR('END')}>
                <div onMouseEnter={() => CURSOR_TEXT(dispatch, 'END')}>
                    <div className={'image-wrapper'}>
                        <ImgLeftParallax src={data?.images?.list?.[0]?.full_path} alt={'background'}/>
                    </div>
                    <Container>
                        <Row>
                            <Col lg={7}>
                                <div className={'title-wrapper'}
                                     onMouseEnter={() => CURSOR_EXCLUSION(dispatch, 'START', 'LARGE')}
                                     onMouseLeave={() => CURSOR_EXCLUSION(dispatch, 'END')}>
                                    <h1 data-magnetism
                                        className={'title'}>{reactHtmlParser(data?.section_data?.subtitle)}</h1>
                                </div>
                            </Col>
                            <Col lg={{offset: 2, span: 3}} className={'top-right'}
                                 onMouseEnter={() => CURSOR_EXCLUSION(dispatch, 'START', 'LARGE')}
                                 onMouseLeave={() => CURSOR_EXCLUSION(dispatch, 'END')}>
                                <h5 data-magnetism
                                    className={'title'}>{reactHtmlParser(data?.section_data?.short_desc)}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3} className={'bottom-left__image'} data-speed={"clamp(1.1)"}>
                                <div className={'image-wrapper reveal'}>
                                    <Img src={data?.posts?.list?.[0]?.images?.[0]?.full_path} alt={'stone'}/>
                                </div>
                            </Col>
                            <Col lg={{offset: 1, span: 4}} className={'bottom-left__text'} data-speed={"clamp(1.1)"}
                                 onMouseEnter={() => CURSOR_EXCLUSION(dispatch, 'START', 'SMALL')}
                                 onMouseLeave={() => CURSOR_EXCLUSION(dispatch, 'END')}>
                                <p data-magnetism>{reactHtmlParser(data?.section_data?.description)}</p>
                            </Col>
                            {isDesktop && (
                                <Col lg={{offset: 1, span: 3}} className={'bottom-right'} data-speed={'clamp(1.3)'}>
                                    <div className={'image-wrapper reveal'}>
                                        <Img src={data?.posts?.list?.[1]?.images?.[0]?.full_path} alt={'stone'}/>
                                    </div>
                                </Col>
                            )}
                        </Row>
                    </Container>
                    <div className={'middle-svg'} data-speed={'clamp(0.9)'}
                         onMouseEnter={() => CURSOR_STICKY(dispatch, 'imStickyy', 'SMALL')}
                         onMouseLeave={() => CURSOR_STICKY(dispatch, 'END')}>
                        <img id="imStickyy" data-magnetism src={'/images/static/banner-middle.png'} alt={'stone'}/>
                    </div>
                    <div className={'bottom-svg'} data-speed={"clamp(1.3)"}
                         onMouseEnter={() => CURSOR_STICKY(dispatch, 'imSticky', 'SMALL')}
                         onMouseLeave={() => CURSOR_STICKY(dispatch, 'END')}>
                        <img id="imSticky" data-magnetism src={'/images/static/banner-bottom.png'} alt={'stone'}/>
                    </div>
                </div>
            </StyledComponent>
        </div>
    );
};

const StyledComponent = styled.section`
  height: 130svh;
  position: relative;
  //&:after {
  //  content: '';
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  height: 100%;
  //  width: 100%;
  //  background: linear-gradient(157deg, rgba(0, 0, 0, 0.00) 30.16%, #000 86.36%);
  //  mix-blend-mode: multiply;
  //}

  .title-wrapper {
    width: fit-content;
    #imVideoBlob{
      background-color: revert;
    }
  }

  .container {
    padding-top: 35svh;
    height: 100svh;
    position: relative;

    h1 {
      color: ${white};
      width: fit-content;
    }

    h5 {
      color: ${white};
      line-height: 32px;
      letter-spacing: -0.72px;
    }

    p {
      color: ${white};
    }

    .row {
      &:last-child {
        position: absolute;
        top: 97svh;
        padding-right: ${props => props.offset ? props.offset + 'px' : ''};
        width: 100%;
        //@media(max-height: 950px){
        //  bottom: -45svh;
        //}
        //@media (min-width: 992px) and (max-width: 1600px) {
        //  top: 53svh;
        //}
        //@media (min-width: 1401px) and (max-width: 1850px) {
        //  top: 58svh;
        //}
      }
    }

    .top-right {
      display: flex;
      align-items: end;
      margin-bottom: -15px;
      z-index: 10;
    }
  }

  .bottom-left {
    &__text {
      padding-left: 0;
      display: flex;
      align-items: flex-start;
      z-index: 10;
      height: fit-content;

      p {
        padding-right: 50px;
      }
    }

    &__image {
      padding-right: 0;
      z-index: 1;

      .image-wrapper {
        position: relative;
        padding-top: calc(500 / 300 * 100%);
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .bottom-right {
    padding-left: 0;
    padding-top: 50px;
    display: block;
    z-index: 1;

    .image-wrapper {
      position: relative;
      padding-top: calc(300 / 270 * 100%);

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  .middle-svg {
    position: absolute;
    top: 32svh;
    right: 38svh;
    z-index: 10;
  }

  .bottom-svg {
    position: absolute;
    bottom: -50px;
    left: 24vw;
    z-index: 1;
  }

  @media (max-width: 991px) {
    height: 100%;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(151deg, rgba(0, 0, 0, 0.00) -6.5%, #000 71.81%);
      mix-blend-mode: multiply;
    }

    h1 {
      margin-bottom: 40px;
      z-index: 10;
      position: relative;
    }

    .container {
      height: unset;
    }

    .row {
      &:last-child {
        position: unset !important;
        padding-right: unset !important;
        flex-direction: column-reverse;
      }
    }

    .bottom-right {
      display: none;
    }

    .bottom-left {
      &__text {
        padding-left: 15px;
        margin-top: 60px;
        margin-bottom: 40px;
      }

      &__image {
        z-index: 1;

        .image-wrapper {
          padding-top: unset;
          height: 400px;
          width: 255px;
          margin-bottom: -125px;
        }
      }
    }

    .middle-svg {
      position: absolute;
      top: 55vh;
      right: unset;
      left: -32px;
      height: 120px;
      width: 114px;
      filter: blur(4px);
    }

    .bottom-svg {
      height: 150px;
      width: 150px;
      position: absolute;
      bottom: -58px;
      left: unset;
      right: 15px;
      z-index: 1;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    height: 100%;
    .container {
      height: unset;
    }

    .bottom-svg {
      bottom: -82px;
      left: 32vw;
      right: unset;
    }
  }
`;

export default MyComponent;
