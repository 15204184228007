import {createGlobalStyle, css} from 'styled-components';
import {black, hover, text, title, Transition, white} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }


  body {
    font-family: 'Banana Grotesk', PP Migra, PP Fragment Serif, Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
  }

  a {
    transition: color .3s ease;
    text-decoration: none;


    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }


  p, a, h1, h2, h4, h3, h5, h6 {
    font-weight: 400;
    margin: 0;
    font-kerning: none;
    letter-spacing: normal;
    font-family: ${title};
    user-select: none;
  }

  img {
    user-select: none;
  }

  h1, h2 {
  }


  #smooth-wrapper, #smooth-content {
    transform: translate3d(0, 0, 0);
  }

  #smooth-content{
      transform: translateY(0);
      opacity: 1;
      filter: blur(0px);
      will-change: transform, opacity, filter;
  }

  .page-transition-overlay {
    pointer-events: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }


  h1 {
    font-size: 80px;
    line-height: 100%;
    font-weight: 500;

    span {
      font-family: PP Migra;
      font-size: 80px;
      font-style: italic;
      font-weight: 400;
      line-height: 100%;
    }

    @media (max-width: 767px) {
      font-size: 60px;
      line-height: 90%;
      span {
        font-family: PP Migra;
        font-size: 60px;
        font-style: italic;
        line-height: 90%;
      }

      &.title {
        font-size: 60px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 90% !important;
        letter-spacing: -3px !important;

        span {
          font-size: 60px !important;
          font-style: italic !important;
          font-weight: 400 !important;
          line-height: 90% !important;
        }
      }
    }

    &.title {
      font-size: 100px;
      line-height: 90%;
      letter-spacing: -5px;

      span {
        font-family: PP Migra;
        font-size: 100px;
        font-style: italic;
        font-weight: 400;
        line-height: 90%;
        letter-spacing: 0;
      }
    }
  }

  h2 {
    font-family: 'Banana Grotesk';
    font-size: 60px;
    line-height: 100%;
    font-weight: 500;

    span {
      font-family: PP Migra;
      font-style: italic;
      font-size: 60px;
      line-height: 100%;
      font-weight: 500;
      letter-spacing: 0;
    }

    @media (max-width: 767px) {
      font-size: 40px;
      line-height: 100%;
      span {
        font-size: 40px;
        line-height: 100%;
      }
    }
  }

  h3 {
    font-family: 'Banana Grotesk';
    font-size: 48px;
    line-height: 100%;
    font-weight: 500;
    //@media (min-width: 768px) and (max-width: 1370px) {
    //  font-size: 48px;
    //  line-height: 60px;
    //}
    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 100%;
    }
  }

  h4 {
    font-family: 'Banana Grotesk';
    font-size: 32px;
    line-height: 100%;
    font-weight: 500;
    //@media (min-width: 768px) and (max-width: 1370px) {
    //  font-size: 32px;
    //  line-height: 40px;
    //}
    @media (max-width: 767px) {
      font-size: 24px;
      line-height: 100%;
    }

    span {
      font-family: PP Migra;
      font-style: italic;
      letter-spacing: 0;
    }
  }

  h5 {
    font-family: 'Banana Grotesk';
    font-size: 24px;
    line-height: 100%;
    font-weight: 500;
    //@media (min-width: 768px) and (max-width: 1370px) {
    //  font-size: 24px;
    //  line-height: 125%;
    //}
    @media (max-width: 767px) {
      font-size: 20px;
      line-height: 100%;
    }
  }

  h6 {
    font-family: 'Banana Grotesk';
    font-size: 18px;
    line-height: 100%;
    font-weight: 500;
    //@media (min-width: 768px) and (max-width: 1370px) {
    //  font-size: 18px;
    //  line-height: 150%;
    //}
    @media (max-width: 767px) {
      font-size: 16px;
      line-height: 100%;
    }
  }

  p {
    font-family: 'Banana Grotesk';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    &.subtitle {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
    }
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }

  .overflow {
    overflow: hidden;
  }

  .container-fluid {
    padding: 0;
  }

  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-160 {
    padding-top: 160px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-160 {
    padding-bottom: 160px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pb-130 {
    padding-bottom: 130px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-60 {
    margin-top: 60px;
  }


  @media (min-width: 1500px) {
    .container {
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;

    span {
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 19px;
    }

    &:hover {
      &:after {
        height: 100%;
        width: 100%;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .main-menu {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up {
    .main-menu {
      background: #F1F0EE !important;

      .logo-white {
        display: block;
      }

      .logo-black {
        display: none;
      }

      .Main-menu__wrap__menu__hamburger__text {
        p {
          color: #191D1C !important;
        }
      }

      .main-menu__wrapper {
        .center ul a li {
          color: #191D1C !important;
        }

        .right .main-menu__items {
          p {
            color: #191D1C !important;
          }

          .hamburger-icon {
            svg {
              path {
                stroke: #191D1C !important;
              }
            }
          }
        }

        .logo {
          svg {
            path:not(:nth-child(2)) {
              fill: #191D1C !important;
            }
          }
        }
      }

      .main-menu__search {
        svg {
          path {
            fill: #191D1C !important;
            stroke: #191D1C !important;
          }
        }

        .wrapper {
          svg {
            path {
              fill: #fff !important;
              stroke: #fff !important;
            }
          }
        }

        .social-icons {
          svg {
            path {
              fill: #fff !important;
              stroke: #fff !important;
            }
          }
        }

      }

      .main-menu__search:after {
        background: #191D1C;
      }
    }

    .collection-main {
      background: #F1F0EE !important;

      .logo-white {
        display: block;
      }

      .logo-black {
        display: none;
      }

      .Main-menu__wrap__menu__hamburger__text {
        p {
          color: #F1F0EE !important;
        }
      }

      .main-menu__wrapper {
        .center ul a li {
          color: #F1F0EE !important;
        }

        .right .main-menu__items {
          p {
            color: #F1F0EE !important;
          }

          .hamburger-icon {
            svg {
              path {
                stroke: #F1F0EE !important;
              }
            }
          }
        }

        .logo {
          svg {
            path:not(:nth-child(2)) {
              fill: #F1F0EE !important;
            }
          }
        }
      }

      .main-menu__search {
        svg {
          path {
            fill: #191D1C !important;
            stroke: #191D1C !important;
          }
        }
      }

      .main-menu__search:after {
        background: #F1F0EE;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }


  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }

  .reveal {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  #smooth-content {
    will-change: transform;
  }

  .page-transition {
    display: none;
  }


  form {
    .form-control {
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      background-color: transparent !important;
      padding: 0 0 20px 0;
      margin-bottom: 40px;
      outline: none;
      color: #ffffff;
      box-shadow: none;

      &::placeholder {
        color: #999999;
      }

      &:focus {
        border-color: #FFF;
      }
    }

    textarea {
      min-height: 80px;
      max-height: 80px;
    }
  }

  .modify-footer .footer {
    @media (min-width: 600px) {
      padding-top: 200px;
    }
  }

  //global form
  .global-popup form .form-control {
    border-bottom: 1px solid rgba(10, 14, 18, 0.3);
    color: ${black};
    padding: 0 0 15px 0;
    margin-bottom: 30px;

    &::placeholder {
      color: #999999 !important;
      text-transform: capitalize;
    }

    &:focus {
      border-bottom: 1px solid ${black};
    }
  }

  .services {
    background-color: ${black};

    .working, .insights {
      padding-top: 0;
    }

    .amenities {
      @media (max-width: 992px) {
        padding-top: 0;
      }
    }
  }

  .career-detail {
    background-color: ${black};
    padding-top: 140px;
    padding-bottom: 150px;
    overflow: hidden;
  }

  .team-bio {
    background-color: ${black};
    padding-top: 140px;
  }

  //Director Popup
  .directors-modal {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;

    .modal-dialog {
      max-width: 100% !important;
      margin: 0 !important;
      overflow: hidden !important;
    }


    .modal-content {
      width: 100vw !important;
    }

    .for-close {
      align-items: center;
      cursor: pointer;
      justify-content: flex-end;
      border-bottom: unset;
      padding-bottom: unset;
      position: absolute;
      top: 30px;
      right: 30px;
    }

    .modal-close {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #000;
      position: relative;
      transition: 0.3s ${Transition} all;
      overflow: hidden;


      svg {

      }

      &:after {
        content: '';
        position: absolute;
        height: 0;
        width: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: ${hover};
        transition: all .5s ease;
        border-radius: 19px;
      }

      &:hover {
        border-color: ${hover};
        transition: 0.5s ${Transition} all;

        svg {
          position: relative;
          z-index: 999;

          g {
            line {
              stroke: #ffffff;
            }
          }
        }

        &:after {
          width: 100%;
          height: 100%;
        }
      }
    }

    .modal-body {
      padding: 0;
      background: #F1F0EE;
      height: 100svh;
      position: relative;

      .modal-data {
        width: 100%;
        flex-wrap: wrap;
        padding-top: 100px;

        &__content {
          .top {
            margin-bottom: 40px;

            h3 {
              color: ${black};
              margin-bottom: 5px;
            }

            h6 {
              color: ${black};
              font-weight: 500;
              margin-bottom: 30px;
            }
          }

          .details {
            h6 {
              color: black;
              margin-bottom: 30px;
            }

            p {
              color: ${black};
              margin-bottom: 40px;
            }

            ul {
              li {
                color: ${black};
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                border-bottom: 1px solid rgba(254, 248, 238, 0.25);
                padding-bottom: 10px;

                &:not(:last-child) {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }

        &__img {
          .image-wrapper {
            position: relative;
            padding-top: calc(600 / 570 * 100%);
            overflow: hidden;
            margin-bottom: 20px;
          }
        }
      }
    }

    @media (max-width: 767px) {

      .bar {
        padding-left: 0;
      }

      .for-close {
        margin-bottom: 30px;
        top: 15px;
        right: 0;
      }

      .modal-content {
        overflow-x: hidden;

        h2 {
          font-size: 36px !important;
          font-style: normal;
          font-weight: 700;
          line-height: 100% !important; /* 36px */
        }

        .modal-body {
          .bar {
            padding-right: 0 !important;
          }
        }

        .modal-data__content {
          padding-right: 0 !important;
          overflow: hidden;
        }

        .modal-data__img {
          padding-right: 0 !important;
        }
      }

      .modal-close {
        right: 15px !important;
      }

      .modal-data {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        padding-top: 70px !important;

        .modal-data__img {
          padding-left: 0;
          min-width: 100%;
        }
      }

      .modal-data__content {

      }
    }

  }

`;



